import { InlineNotification } from '@qogita/ui/inline-notification'

import { useNotifications } from '#contexts/Notification'

export function DisplayAppNotifications() {
  const notifications = useNotifications()

  if (!notifications.length) return null

  return (
    <div className="pointer-events-none fixed right-0 top-0 z-50 space-y-4 p-4 lg:max-w-[500px] [&>*]:pointer-events-auto">
      {notifications.map(({ id, message, ...props }) => (
        <InlineNotification
          key={id}
          {...props}
          className="flex-shrink-0"
          variant={props.variant}
        >
          <InlineNotification.Icon />
          <InlineNotification.Content>
            <InlineNotification.Title className="font-bold">
              {message}
            </InlineNotification.Title>
          </InlineNotification.Content>
          <InlineNotification.CloseButton />
        </InlineNotification>
      ))}
    </div>
  )
}
