import { logError } from '@qogita/logging/browser-logger'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
} from 'react'

import { useRefreshSession } from '#src/hooks/api/usePostAuthRefresh'
import { useLandingRoute } from '#src/hooks/utils/useLandingRoute'
import { useRolePermissions } from '#src/hooks/utils/useRolePermissions'
import { getAccessToken } from '#utils/authentication'
import { getPrivateQueryKey } from '#utils/queryKey'
import {
  removeUserIdentificationFromThirdParties,
  trackUserSignedOut,
} from '#utils/report/tracking'

type AuthStatus = 'VERIFIED' | 'INVALID' | 'NOT_SET'

type OptionalContext<T> = T | undefined

const AuthStatusContext = createContext<OptionalContext<AuthStatus>>(undefined)
const LogoutContext = createContext<OptionalContext<() => void>>(undefined)
const SignatureContext =
  createContext<OptionalContext<string | null>>(undefined)

export const fetchLogout = async (accessToken: string) => {
  return fetch(`/api/auth/logout/`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

type AuthenticationProviderProps = {
  children: ReactElement
}

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const queryClient = useQueryClient()

  const {
    invalidate: invalidateRefreshSession,
    data: refreshToken,
    isError,
  } = useRefreshSession()

  const logout = useCallback(async () => {
    const lastToken = await getAccessToken()
    try {
      await fetchLogout(lastToken)
    } catch (error) {
      logError(error)
    } finally {
      removeUserIdentificationFromThirdParties()
      trackUserSignedOut()
      queryClient.clear()
      invalidateRefreshSession()
    }
  }, [queryClient, invalidateRefreshSession])

  let authStatus: AuthStatus = 'NOT_SET'

  if (refreshToken) authStatus = 'VERIFIED'
  if (isError) authStatus = 'INVALID'

  useEffect(() => {
    if (authStatus === 'INVALID') {
      queryClient.resetQueries({
        predicate: (query) => query.queryKey[0] === getPrivateQueryKey(),
      })
    }
  }, [authStatus, queryClient])

  return (
    <SignatureContext.Provider value={refreshToken?.signature ?? null}>
      <AuthStatusContext.Provider value={authStatus || 'INVALID'}>
        <LogoutContext.Provider value={logout}>
          {children}
        </LogoutContext.Provider>
      </AuthStatusContext.Provider>
    </SignatureContext.Provider>
  )
}

export const useSignature = () => {
  const value = useContext(SignatureContext)

  if (value === undefined) throw new Error('SignatureContext is not available')

  return value
}

export const useAuthStatus = (): AuthStatus => {
  const value = useContext(AuthStatusContext)

  if (value === undefined) {
    throw new Error('AuthStatusContext is not available')
  }

  return value
}

export const useLogout = () => {
  const value = useContext(LogoutContext)

  if (value === undefined) {
    throw new Error('LogoutContext is not available')
  }

  return value
}

export function useAppRedirection(shouldRedirectToLogin: boolean) {
  const landingRoute = useLandingRoute()
  const { replace, push, query, asPath } = useRouter()
  const redirectParameter = query.redirectPath as string

  const onAppMount = useCallback(() => {
    if (shouldRedirectToLogin) {
      const redirectValue = new URLSearchParams({ redirectPath: asPath })

      replace(`/login?${redirectValue}`)
    }
  }, [asPath, replace, shouldRedirectToLogin])

  const redirectVisitor = useCallback(() => {
    push(redirectParameter ?? landingRoute)
  }, [push, redirectParameter, landingRoute])

  return {
    onAppMount,
    redirectVisitor,
  }
}

/**
 * @description Ensure that access is only given to either a seller or a warehouse user
 *
 * We don't have Server side checks, so in a perfect world I would throw a not found, alas this is not possible as I need the user payload to check the type
 */
export function useValidatePageAccess(
  expectUserType: ReturnType<typeof useRolePermissions>['type'],
): void {
  const { push } = useRouter()
  const { type } = useRolePermissions()

  useEffect(() => {
    if (expectUserType !== type) push('/')
  }, [expectUserType, push, type])
}
