import { logError } from '@qogita/logging/browser-logger'
import { QogitaLogoWordmark } from '@qogita/ui/qogita-logo'
import { storyblokEditable } from '@storyblok/react'
import clsx from 'clsx'
import Link from 'next/link'
import Script from 'next/script'
import { AnchorHTMLAttributes, ReactNode, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { PageStoryblok } from '#src/types/storyblok-component-types'
import { trackFooterLinkClicked } from '#src/utils/report/tracking'

import {
  AmexIcon,
  ApplePayIcon,
  GooglePayIcon,
  IdealIcon,
  InstagramIcon,
  LinkedinIcon,
  MasterCardIcon,
  TwitterIcon,
  UnionPayIcon,
  VisaIcon,
} from './assets'

const date = new Date()

const AnchorWithTracking = ({
  href,
  ...props
}: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick' | 'href'> & {
  href: string
}) => {
  return (
    <a
      {...props}
      href={href}
      onClick={() => {
        trackFooterLinkClicked({ link: href })
      }}
    />
  )
}

const HubspotSubscribe = () => {
  return (
    <>
      <Script id="hubspot-subscribe-form">
        {`
hbspt.forms.create({
  region: "eu1",
  portalId: "139645065",
  formId: "40e163ee-e789-4477-9b17-070527f3b63a",
  target: '#hubspot-subscribe-form',
  cssClass: 'hubspot-subscribe-form',
});
        `}
      </Script>
      <div id="hubspot-subscribe-form" />
    </>
  )
}

type FooterLinkListProps = {
  children: ReactNode
}
const FooterLinkList = ({ children }: FooterLinkListProps) => {
  return <ul className="flex flex-col gap-2">{children}</ul>
}

type FooterLinkListHeaderProps = {
  children: ReactNode
}
const FooterLinkListHeader = ({ children }: FooterLinkListHeaderProps) => {
  return <h2 className="mb-2 text-base font-bold">{children}</h2>
}

type FooterRowProps = {
  children: ReactNode | undefined
  className?: string
}
const FooterRow = ({ children, className }: FooterRowProps) => {
  return (
    <div>
      <div
        className={clsx('mx-auto max-w-[1072px] px-4 py-12 md:px-6', className)}
      >
        {children}
      </div>
    </div>
  )
}

type FooterLinkProps = {
  href: string
  children: ReactNode
  className?: string
  target?: string
}
const FooterLink = ({ href, children, className, target }: FooterLinkProps) => {
  return (
    <AnchorWithTracking
      href={href}
      className={clsx('outline-primary-700 outline-offset-4', className)}
      target={target}
      rel="noreferrer"
    >
      {children}
    </AnchorWithTracking>
  )
}

const CmsFooter = ({ pageBlok }: { pageBlok: PageStoryblok }) => {
  const footer = pageBlok.footer[0]

  if (!footer) {
    throw new TypeError(
      'Invalid footer: expected an array of storyblok components with at least one element',
    )
  }

  if (typeof footer === 'string') {
    // will never happen because we resolve relations in the api request
    throw new TypeError(
      'Invalid footer: did you resolve the storyblok relations?',
    )
  }

  return (
    <FooterRow
      className="grid grid-cols-2 gap-x-4 gap-y-14 py-12 sm:grid-cols-2 md:flex md:justify-between md:gap-x-20"
      {...storyblokEditable(footer.content)}
    >
      {footer?.content.sections?.map((blok) => {
        return (
          <div key={blok._uid}>
            <FooterLinkListHeader>{blok.title}</FooterLinkListHeader>
            <FooterLinkList>
              {blok.links?.map((blok) => (
                <li key={blok._uid}>
                  <FooterLink
                    target={blok.link.target}
                    // If the link is an `internal` link in Storyblok it'll have a nested story, otherwise if external we can use the bare `url`
                    // https://www.storyblok.com/docs/guide/in-depth/rendering-the-link-field
                    href={blok.link.story?.url ?? blok.link.url}
                  >
                    {blok.label}
                  </FooterLink>
                </li>
              ))}
            </FooterLinkList>
          </div>
        )
      })}
    </FooterRow>
  )
}

const Footer = ({
  pageBlok,
}: {
  pageBlok: PageStoryblok | null
}): ReactNode => {
  const footerRef = useRef<HTMLElement | null>(null)

  return (
    <footer
      className="relative border-t border-t-gray-900 bg-white"
      ref={footerRef}
    >
      <div className="divide-y divide-gray-900">
        <FooterRow className="flex flex-col items-stretch justify-between sm:flex-row sm:gap-x-6">
          <div className="mb-12 flex flex-col sm:mb-0 sm:justify-between">
            <AnchorWithTracking
              href="https://www.qogita.com/"
              target="_blank"
              rel="noreferrer"
            >
              <QogitaLogoWordmark
                className="mb-6 h-[2.5rem] w-[9rem]"
                theme="black"
              />
            </AnchorWithTracking>
            <ul className="flex list-none flex-row items-center gap-6">
              <li>
                <AnchorWithTracking href="https://twitter.com/qogita">
                  <TwitterIcon
                    title="Twitter"
                    className="h-4.5 w-4.5 fill-gray-900"
                  />
                </AnchorWithTracking>
              </li>
              <li>
                <AnchorWithTracking href="https://www.linkedin.com/company/qogita/">
                  <LinkedinIcon
                    title="Linkedin"
                    className="h-4.5 w-4.5 fill-gray-900"
                  />
                </AnchorWithTracking>
              </li>
              <li>
                <AnchorWithTracking href="https://www.instagram.com/qogita_wholesale/">
                  <InstagramIcon
                    title="Instagram"
                    className="h-4.5 w-4.5 fill-gray-900"
                  />
                </AnchorWithTracking>
              </li>
            </ul>
          </div>
          <HubspotSubscribe />
        </FooterRow>

        <ErrorBoundary fallback={null} onError={(error) => logError(error)}>
          {pageBlok ? <CmsFooter pageBlok={pageBlok} /> : null}
        </ErrorBoundary>
        <FooterRow className="flex flex-col gap-y-9 lg:flex-row lg:justify-between lg:gap-y-0">
          <span className="text-base text-gray-700">
            &copy; {date.getFullYear()} Qogita. All rights reserved.
          </span>
          <ul className="flex flex-col items-start gap-2 divide-gray-300 sm:flex-row sm:items-center sm:gap-0 sm:divide-x">
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:pr-5"
                href="https://www.qogita.com/legal/cookies/"
              >
                Cookies
              </FooterLink>
            </li>
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:px-5"
                href="https://www.qogita.com/legal/privacy/"
              >
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <Link
                href="/legal/terms/"
                className="outline-primary-700 whitespace-nowrap text-gray-700 outline-offset-4 sm:pl-5"
              >
                Terms of Use
              </Link>
            </li>
          </ul>
          <div className="flex gap-4 lg:justify-end">
            <VisaIcon title="Visa" className="h-[1.125rem]" />
            <MasterCardIcon title="Mastercard" className="h-[1.125rem]" />
            <AmexIcon title="American Express" className="h-[1.125rem]" />
            <ApplePayIcon title="Apple Pay" className="h-[1.125rem]" />
            <GooglePayIcon title="Google Pay" className="h-[1.125rem]" />
            <UnionPayIcon title="UnionPay" className="h-[1.125rem]" />
            <IdealIcon title="iDEAL" className="h-[1.125rem]" />
          </div>
        </FooterRow>
      </div>
    </footer>
  )
}

export { Footer }
