import {
  ArchiveBoxIcon,
  ChartBarIcon,
  ClipboardDocumentCheckIcon,
  FireIcon,
  HomeIcon,
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
  ReceiptPercentIcon,
} from '@heroicons/react/24/solid'
import { Navigation } from '@qogita/ui/navigation'
import Link from 'next/link'

import { useUserContext } from '#contexts/User'
import { isProduction } from '#src/constants/featureFlags'
import { routes } from '#utils/routes'

type SellerSidebarProps = {
  validateIsActiveLink: (testPath: RegExp) => boolean
  onClickRoute?: () => void
}

export function SellerSidebar({
  validateIsActiveLink,
  onClickRoute,
}: SellerSidebarProps) {
  const user = useUserContext()

  const showInternalNavigationItems = Boolean(user?.isQogitaUser)

  return (
    <Navigation>
      <Navigation.Item
        as={Link}
        href="/dashboard"
        isActive={validateIsActiveLink(/\/dashboard\//)}
        onClick={onClickRoute}
      >
        <Navigation.Icon>
          <HomeIcon />
        </Navigation.Icon>
        Dashboard
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.orders({ status: 'CURRENT', page: 1 })}
        isActive={validateIsActiveLink(/\/orders\//)}
        onClick={onClickRoute}
      >
        <Navigation.Icon>
          <ArchiveBoxIcon />
        </Navigation.Icon>
        Orders
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.inventory({ page: 1 })}
        isActive={validateIsActiveLink(/\/inventory\/\?/)}
        onClick={onClickRoute}
      >
        <Navigation.Icon>
          <ClipboardDocumentCheckIcon />
        </Navigation.Icon>
        Inventory
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.priceReductions.index}
        isActive={validateIsActiveLink(/(\/price-reductions\/)/)}
        onClick={onClickRoute}
        data-dd-action-name="Price reductions Navigation Item"
      >
        <div className="flex items-center">
          <Navigation.Icon>
            <ReceiptPercentIcon />
          </Navigation.Icon>
          Price reductions
        </div>
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.trendingProducts}
        isActive={validateIsActiveLink(/(\/trending-products\/)/)}
        onClick={onClickRoute}
      >
        <Navigation.Icon>
          <FireIcon />
        </Navigation.Icon>
        Trending products
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.insights}
        isActive={validateIsActiveLink(/(\/insights\/)/)}
        onClick={onClickRoute}
        data-dd-action-name="Insights Navigation Item"
      >
        <Navigation.Icon>
          <PresentationChartBarIcon />
        </Navigation.Icon>
        Insights
      </Navigation.Item>

      {showInternalNavigationItems ? (
        <Navigation.Section
          isActive={validateIsActiveLink(
            /(\/internal\/|\/seller-initial-checker\/)/,
          )}
        >
          <Navigation.SectionHeading>
            <Navigation.Icon>
              <ChartBarIcon />
            </Navigation.Icon>
            Internal
          </Navigation.SectionHeading>

          <Navigation.SectionItems>
            <Navigation.Item
              as={Link}
              href="/internal/seller-initial-checker"
              isActive={validateIsActiveLink(
                /^\/internal\/seller-initial-checker/,
              )}
              onClick={onClickRoute}
            >
              Seller GTIN checker
            </Navigation.Item>

            <Navigation.Item
              as={Link}
              href="/internal/seller-onboarding"
              onClick={onClickRoute}
              isActive={validateIsActiveLink(/^\/internal\/seller-onboarding/)}
            >
              Seller onboarding
            </Navigation.Item>

            {isProduction ? null : (
              <Navigation.Item
                as={Link}
                href="/internal/create-test-sale"
                onClick={onClickRoute}
                isActive={validateIsActiveLink(/^\/internal\/create-test-sale/)}
              >
                Create test sale
              </Navigation.Item>
            )}
          </Navigation.SectionItems>
        </Navigation.Section>
      ) : null}

      <Navigation.Item
        as={Link}
        href="/help-center"
        isActive={validateIsActiveLink(/\/help-center\//)}
        onClick={onClickRoute}
      >
        <Navigation.Icon>
          <QuestionMarkCircleIcon />
        </Navigation.Icon>
        Help center
      </Navigation.Item>
    </Navigation>
  )
}
