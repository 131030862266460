import { useCanaryClient } from '@qogita/canary-client/provider'
import type { User } from '@qogita/canary-types'
import { useQuery } from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'

import { useRefreshSession } from '#hooks/api/usePostAuthRefresh'
import { getUserQueries } from '#src/api/user-queries'
import { getIsValidPortalUser } from '#src/utils/getIsValidPortalUser'
import { isQogitaUserEmail } from '#src/utils/isQogitaUserEmail'
import { identifyUser } from '#src/utils/report/tracking'
import { getIsAccessTokenValid } from '#utils/authentication'

import { useLogout } from './Authentication'

export type ClientUser = User & { isQogitaUser: boolean }

type UserContext = ClientUser | null

const UserContext = createContext<UserContext>(null)

export const UserProvider = (props: { children: React.ReactNode }) => {
  const canaryClient = useCanaryClient()
  const userQueries = getUserQueries(canaryClient)
  const logout = useLogout()
  const [user, setUser] = useState<UserContext | null>(null)
  const refreshSessionQuery = useRefreshSession()

  const { data, error, fetchStatus } = useQuery({
    ...userQueries.detail(),
    enabled: getIsAccessTokenValid(
      refreshSessionQuery?.data?.accessToken || '',
    ),
  })

  useEffect(() => {
    // Only set the user to null if network is idle and stale data available
    if (!data && fetchStatus === 'idle') setUser(null)
  }, [fetchStatus, data])

  useEffect(() => {
    if (data) {
      const isQogitaUser = isQogitaUserEmail(data.email)

      if (getIsValidPortalUser(data)) {
        setUser({
          ...data,
          isQogitaUser,
        })
      } else {
        logout()
      }
    }
  }, [data, logout])

  useEffect(() => {
    if (user?.qid) {
      identifyUser(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.qid])

  useEffect(() => {
    if (error) logout()
  }, [error, logout])

  return <UserContext.Provider {...props} value={user} />
}

export const useUserContext = (): UserContext => {
  return useContext(UserContext)
}

export const useUser = () => {
  const user = useContext(UserContext)

  if (!user) {
    throw new Error(
      'useUser must be called only when UserProvider has an User.',
    )
  }

  return user
}
