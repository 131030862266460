import { cn } from '@qogita/ui/utils/cn'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { CenteredContent } from '#components/cms/CenteredContent'
import { StoryPanelStoryblok } from '#types/storyblok-component-types'

import { CmsPanelErrorBoundary } from './CmsPanelErrorBoundary'

const StoryPanelComponent = ({ blok }: { blok: StoryPanelStoryblok }) => {
  const { layout, content, image, header } = blok
  const [imageBlok] = image
  const [headerBlok] = header || []
  const [contentBlok] = content || []

  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div
          className={cn('grid grid-cols-1 gap-8 py-12 md:gap-x-24', {
            'md:grid-cols-[1fr_2fr]': layout === 'image-left',
            'md:grid-cols-[2fr_1fr]': layout === 'image-right',
          })}
        >
          <div
            className={cn({
              'md:order-2': layout === 'image-right',
            })}
          >
            <StoryblokComponent key={imageBlok?._uid} blok={imageBlok} />
          </div>
          <div
            className={cn('flex flex-col gap-6', {
              'md:order-1': layout === 'image-right',
            })}
          >
            {headerBlok ? (
              <StoryblokComponent key={headerBlok._uid} blok={headerBlok} />
            ) : null}
            {contentBlok ? (
              <StoryblokComponent key={contentBlok._uid} blok={contentBlok} />
            ) : null}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}

export const StoryPanel = ({ blok }: { blok: StoryPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <StoryPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
