import { getFormattedDateTime } from '#src/utils/getFormattedDateTime'

import { Continent } from './countries'

export const EMAILS = {
  sales: 'sales@qogita.com',
  support: 'support@qogita.com',
  procurement: 'procurement@qogita.com',
  fulfilment: 'fulfilment@qogita.com',
  success: 'success@qogita.com',
}

export const regionNames = {
  EUROPE: 'Europe (EU+UK)',
  USA: 'USA',
  WORLD: 'World',
} as const

export const qogitaInfo = {
  /* NOTE commenting this out as it will come back soon */
  // contactNumber: '+49 157 5246 3074',
  locations: {
    uk: {
      nameShort: 'QOGITA UK',
      nameLong: 'Qogita UK Ltd.',
      legalName: 'Qogita UK LTD',
      address: '33 Broadwick St',
      city: 'London',
      postcode: 'W1F 0DQ',
      country: 'United Kingdom',
    },
    eu: {
      nameShort: 'QOGITA EU',
      nameLong: 'Qogita EU B.V.',
      legalName: 'Qogita EU BV',
      address: 'Weesperplein 4B',
      city: 'Amsterdam',
      postcode: '1018 XA',
      country: 'Netherlands',
      phone: '+31 208098587',
    },
  },
}

export const CONTINENT: Record<Continent, string> = {
  AFRICA: 'Africa',
  ASIA: 'Asia',
  EUROPE: 'Europe',
  NORTH_AMERICA: 'North America',
  OCEANIA: 'Oceania',
  SOUTH_AMERICA: 'South America',
  MIDDLE_EAST: 'Middle East',
  ANTARCTICA: 'Antarctica',
}

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

export const GREETINGS = {
  hello: 'Hello',
} as const

export const PAGE_TITLES = {
  SellerInitialCheck: 'Seller GTIN checker',
  HelpCenter: 'Help center',
  SellerOnboarding: 'Seller onboarding',
}

export const ERRORS = {
  failedToFetch: 'Something went wrong. Please try again.',
  postBank: {
    default: 'There was an error submitting bank details. Please try again.',
  },
  postCarrierBooking: {
    default: 'There was an error submitting carrier booking. Please try again.',
  },
  getShipmentGroup: {
    default: 'There was an error fetching shipment group. Please try again.',
  },
  getShippingCarrierList: {
    default: 'There was an error loading the shipment carriers.',
  },
  getSaleList: {
    default: 'There was an error loading the orders, please try again.',
    404: 'No results found.',
    500: 'Something went wrong, please try again.',
  },
  getOffersInfo: {
    default:
      'There was an error loading this your offers info, please try again.',
  },
  getOffers: {
    default: 'There was an error loading your offers, please try again.',
  },
  getSale: {
    default: 'There was an error loading this order, please try again.',
    404: 'No order found.',
  },
  getOffer: {
    default: 'There was an error loading this product, please try again.',
    404: 'No product found.',
  },
  getSellerLeads: {
    default: 'There was an error loading your seller leads, please try again.',
  },
  getSaleDocuments: {
    default: 'There was an error loading these documents, please try again.',
    404: 'No documents found.',
  },
  getSaleLines: {
    default: 'There was an error loading the lines, please try again.',
  },
  getSaleDeclineReasons: {
    default:
      'There was an error loading the decline sale reasons, please try again.',
  },
  getSaleCommercialInvoice: {
    default:
      'There was an error loading the commercial invoice, please try again.',
    404: 'No commercial invoice found.',
  },
  deleteSaleDocument: {
    default: 'There was an error deleting the sale document',
  },
  deleteShipment: {
    default: 'There was an error deleting the track and trace',
  },
  postShipment: {
    default: 'There was an error creating the track and trace',
    invalidCodes: 'One or more invalid track and trace numbers were removed',
  },
  postAcceptSla: {
    default: 'There was an error accepting the SLA, please try again.',
  },
  postSaleLine: {
    400: 'Please try again.',
  },
  postSaleLineReset: {
    default: 'Please try again.',
  },
  postSalePay: {
    default: 'We were unable to process this payment, please try again later.',
    wiseError:
      'We are having trouble processing payments at the moment. Please try again in a few hours.',
    exceedsShippingAmountLimit: 'Maximum shipping value exceeded',
    amountExceedsLimit:
      'The requested amount exceeds our maximum value. Please try a smaller amount or contact procurement@qogita.com.',
    amount:
      'The requested amount exceeds our maximum value. Please try a smaller amount or contact procurement@qogita.com.',
    currencyMismatch:
      'Stock offer currency does not match payment currency. Please contact "procurement@qogita.com" for payment.',
    500: 'There was an error processing this payment, please try again later.',
    502: 'There was an error processing the payment, please try again later.',
    paymentWillBeRevisedAndEnabledByOurTeam:
      'Your payment needs to be approved before proceeding. We will be in touch to confirm this within 1 working day.',
  },
  patchSale: {
    default: 'There was an error updating this order',
  },
  patchSaleDocument: {
    default: 'There was an error updating this document.',
  },
  putUser: {
    default: 'There was an error updating your details.',
  },
  activateAccount: {
    default:
      'There was an error updating your password. Please try again or speak to a member of our team if the issue persists.',
    invalidSecret:
      'The link has expired or is invalid. Please contact us for a new link.',
    expiredSecret:
      'Your activation link has expired, please go to https://www.qogita.com/reset-password/ to receive a new link.',
  },
  putStockMapping: {
    default: 'There was an error uploading the stock list.',
    forbidden: 'You do not have permission to upload stock list.',
  },
  gtinsTopCategoriesCheck: {
    selectFile: 'Please select a file',
    selectRow:
      'Please select the row containing column headings in the inventory table.',
    selectGtinColumn: 'Please select the column containing GTIN.',
    selectPriceColumn: 'Please select the column containing the price.',
    defaultError: 'There was an error, please try again.',
    forbidden: "You don't have permission to use the seller GTIN checker.",
    fileIssue:
      "We couldn't parse the file. Please try again with a different file.",
    zendeskDealNotFound:
      "We couldn't find a Zendesk deal for the provided ID. Please double-check the Zendesk ID.",
    selectCurrency: 'Please select the currency.',
    selectSellingUnitColumn:
      'Please select the column containing the selling unit.',
    selectInventoryColumn: 'Please select the column containing the inventory.',
    taskNotFoundAfterAllRetries:
      'An issue occurred while processing stocklist. Please reach out to Product with stocklist and screenshot if issue persists.',
  },
  gtins: {
    defaultError: 'There was an error, please try again.',
    forbidden: "You don't have permission to use the seller GTIN checker",
    fileIssue:
      "We couldn't parse the file. Please try again with a different file.",
    zendeskDealNotFound:
      "We couldn't find a zendesk deal for the provided id. Please double check the zendesk id provided.",
  },
  phoneVerifyToken: {
    default: 'Invalid verification code. Please try again.',
  },
  getSales: {
    default: 'There was an error loading your orders, please try again.',
  },
  getClaimSaleProcessingDetailList: {
    default: 'There was an error loading your claims, please try again.',
  },
  postSellerLeadComplete: {
    default: 'There was an error completing the seller lead, please try again.',
  },
  putSellerLead: {
    default: 'There was an error updating the seller lead, please try again.',
  },
} as const

export const NOTIFICATIONS = {
  success: {
    paymentTriggered: {
      title: 'Payment Triggered',
      message: 'This order has been moved to your current orders page',
    },
    declineSale: {
      message: 'Order declined',
    },
    patchSale: {
      // we use `File Updated` because this success message is shown in 2 cases:
      // when uploading a file, or when removing the file
      message: 'File updated',
    },
    saleDocument: {
      // we use `File Updated` because this success message is shown in 2 cases:
      // when uploading a file, or when removing the file
      message: 'File updated',
    },
    saveShipments: 'Shipping details saved',
    deleteShipment: 'Shipping details removed',
    addShipment: 'Track and trace number saved',
    activateAccount: 'Account successfully activated',
    putAddress: 'Address updated.',
    postAddress: 'Address created.',
    updateCoc: 'Company details updated',
    resendPhoneVerificationCode: 'New code sent.',
    resendUserEmailToken: 'New email sent.',
  },
  caution: {
    paySale: {
      message:
        'A bank transfer for this payment has been triggered and the order has been moved to your current orders',
    },
  },
  warning: {
    paymentFailed: {
      message:
        'We were unable to trigger your payment, please contact procurement@qogita.com if the issue continues',
    },
    deleteShipment: {
      message:
        'We are unable to delete this tracking number, please contact procurement@qogita.com is this issue continues',
    },
    postShipment: {
      message: 'We were unable to add that track and trace, please try again.',
    },
    deleteSaleDocuments: {
      message:
        'We were unable to delete this document, please refresh this page.',
    },
    getShippingCarrierList: {
      message:
        'We were unable to load the shipping carriers, please refresh this page.',
    },
    declineSale: {
      message: 'We were unable to decline this order, please try again.',
    },
    completeSale: {
      message: 'We were unable to confirm this order, please try again.',
    },
    patchSale: {
      message:
        'We were unable to update your order, please refresh the page and try again.',
    },
    saleDocument: {
      message:
        'We were unable to update your order, please refresh the page and try again.',
    },
    newOrderCosts: {
      message:
        'We were unable to update your order, please refresh the page and try again.',
    },
    orderIsAboutToExpired: {
      title: 'Order is about to expire',
      message: ({ expiredAt }: { expiredAt: number }) =>
        `This order will expire if it is not fulfilled before ${getFormattedDateTime(
          expiredAt,
          'en-GB',
          {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            year: 'numeric',
            month: 'long',
            timeZoneName: 'shortGeneric',
          },
        )}.`,
    },
  },
}

export const MODAL_COPY = {
  // This modal appears when the user is about to trigger a payment an order on the CURRENT ORDERS page
  triggerPayment: {
    title: 'Trigger payment',
    actions: {
      primary: 'Confirm',
      secondary: 'Cancel',
    },
  },
  // This modal appears when the user wants to trigger a payment but has not the profile completed
  completeProfile: {
    title: 'Please complete your profile',
    actions: {
      primary: 'Complete your profile',
      secondary: 'Cancel',
    },
    message: 'To trigger payment, please complete missing information.',
  },
  // This modal appears when the user is about to COMPLETE an order on the CURRENT ORDERS page
  completeSale: {
    title: 'Complete your order',
    message:
      'By completing your order, you will not be able to edit it, and it will be moved to your past orders page.',
    actions: {
      primary: 'Complete',
      secondary: 'Cancel',
    },
  },
  // This modal appears when the user is about to clear a pallet on the CURRENT ORDER page
  clearPallet: {
    title: 'Clear pallet',
    message: 'Are you sure you want to clear this pallet?',
    actions: {
      primary: 'Clear',
      secondary: 'Cancel',
    },
  },
  // This modal appears when the user is about to change the shipment method on the CURRENT ORDER page
  shipmentMethodChange: {
    title: 'Change shipping method',
    description:
      'We only support one shipping method per order. If you want to change your shipping method, please clear all fields before continuing.',
    actions: {
      primary: 'Okay',
    },
  },
}
