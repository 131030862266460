import { InlineNotification } from '@qogita/ui/inline-notification'
import {
  ComponentProps,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import { useInterval } from '#hooks/utils/useInterval'
import { ONE_SECOND_MS } from '#src/constants/time'

type NotificationVariant = Exclude<
  ComponentProps<typeof InlineNotification>['variant'],
  'neutral'
>

interface ExternalNotification {
  message: string
  variant: NotificationVariant
  onClose: () => void
}

/**
 * @description This is added on creation internally
 */
interface InternalNotification extends ExternalNotification {
  id: string
  expiryTimestamp: number | 'persist'
}

type CreateNotificationConfig = {
  timeout?: number | null
}

type NotificationBaseAction = (
  message: InternalNotification['message'],
  config: Pick<ExternalNotification, 'variant'> & CreateNotificationConfig,
) => void

type NotificationStrictAction = (
  message: InternalNotification['message'],
  config?: CreateNotificationConfig,
) => void

// Note: Ensure all variants have util available
type VariantNotifications = {
  createSuccessNotification: NotificationStrictAction
}

interface NotificationActions extends VariantNotifications {
  createNotification: NotificationBaseAction
}

const notificationsItemsContext = createContext<
  InternalNotification[] | undefined
>(undefined)
const notificationsActionsContext = createContext<
  NotificationActions | undefined
>(undefined)

function useNotificationLogic() {
  const [notifications, setNotifications] = useState<InternalNotification[]>([])

  const closeableNotifications = notifications.filter(
    (x) => typeof x.expiryTimestamp === 'number',
  )

  useInterval(
    () => {
      // Note: Check for notification expiry for non persisted items
      setNotifications((items) =>
        items.filter((item) => {
          // Persisted items are not removed
          if (item.expiryTimestamp === 'persist') return true

          // Remove items that have expired
          return Date.now() <= item.expiryTimestamp
        }),
      )
    },
    closeableNotifications.length ? ONE_SECOND_MS : null,
  )

  const createItem: NotificationBaseAction = (message, config) => {
    const handleOnClose = (idToRemove: InternalNotification['id']) => {
      setNotifications((items) => items.filter((x) => x.id !== idToRemove))
    }

    setNotifications((existingNotifications) => {
      const id = Date.now().toString()
      const expiryTimestamp =
        config.timeout === null
          ? 'persist'
          : Date.now() + (config.timeout ?? 3000)

      return [
        ...existingNotifications,
        {
          ...config,
          variant: config.variant,
          message,
          id,
          expiryTimestamp,
          onClose: () => handleOnClose(id),
        },
      ]
    })
  }

  const createNotificationVariants: VariantNotifications = {
    createSuccessNotification: (message, config) =>
      createItem(message, { ...config, variant: 'success' }),
  }

  return {
    notifications,
    createNotification: useCallback(createItem, []),
    ...createNotificationVariants,
  }
}

/**
 * @description Two providers were used to avoid re-renders due to notifications frequent re-renders
 */
export function NotificationProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { notifications, ...actions } = useNotificationLogic()

  return (
    <notificationsItemsContext.Provider value={notifications}>
      <notificationsActionsContext.Provider value={actions}>
        {children}
      </notificationsActionsContext.Provider>
    </notificationsItemsContext.Provider>
  )
}

export function useNotificationsActions() {
  const context = useContext(notificationsActionsContext)

  if (!context) {
    throw new Error("You need <NotificationProvider />' to get actions.")
  }

  return context
}

export function useNotifications() {
  const context = useContext(notificationsItemsContext)

  if (!context) {
    throw new Error("You need <NotificationProvider />' to get items.")
  }

  return context
}
