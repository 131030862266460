import { logError } from '@qogita/logging/browser-logger'
import { Anchor } from '@qogita/ui/anchor'
import React from 'react'
import { TopBar } from 'src/components/shared/TopBar'

import { EMAILS } from '#src/constants/copy'

type ErrorBoundaryProps = {
  fallback: React.ReactNode
  children: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: unknown) {
    logError(error)
  }

  render() {
    if (!this.state.hasError) return this.props.children
    return this.props.fallback
  }
}

export function DefaultErrorBoundaryFallback() {
  const supportLink = (
    <Anchor
      href={`mailto:${EMAILS.support}`}
      className="text-info-700 underline"
    >
      {EMAILS.support}
    </Anchor>
  )

  return (
    <div className="flex min-h-screen flex-col">
      <TopBar />

      <main>
        <div className="mx-auto flex max-w-[54rem] flex-col items-center justify-center px-8 py-16 sm:flex-row">
          <div className="basis-4/5 text-center">
            <h2 className="pb-8 text-3xl">Oops, there is an error!</h2>

            <p className="pb-8">
              Please try again, return to home or contact {supportLink} if the
              issue continues
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}
