import { Navigation } from '@qogita/ui/navigation'
import Link from 'next/link'

import { routes } from '#utils/routes'

type LogisticsSidebarProps = {
  validateIsActiveLink: (testPath: RegExp) => boolean
  onClickRoute?: () => void
}

export function LogisticsSidebar({
  validateIsActiveLink,
  onClickRoute,
}: LogisticsSidebarProps) {
  return (
    <Navigation>
      <Navigation.Item
        as={Link}
        href={routes.incomingPackages()}
        isActive={validateIsActiveLink(/\/incoming\//)}
        onClick={onClickRoute}
      >
        Incoming packages
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.warehouse}
        isActive={validateIsActiveLink(/\/confirmed-packages\//)}
        onClick={onClickRoute}
      >
        In the warehouse
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.shipments}
        isActive={validateIsActiveLink(/\/shipments\//)}
        onClick={onClickRoute}
      >
        Shipments
      </Navigation.Item>

      <Navigation.Item
        as={Link}
        href={routes.readyToShip}
        isActive={validateIsActiveLink(/\/ready-to-ship\//)}
        onClick={onClickRoute}
      >
        Ready to ship
      </Navigation.Item>
    </Navigation>
  )
}
