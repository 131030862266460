import { z } from 'zod'

export const urlOrIPSchema = z
  .union([z.string().url(), z.string().ip()])
  .refine(
    (value) => !value.endsWith('/'),
    (value) => ({ message: `${value} has trailing slashes` }),
  )

/**
 * Zod coerce.boolean acts simply. It pipes the value throught the Boolean() constructor, so "false" will be true.
 * We should allow to use "0", "false" as falsy and "1", "true" as truthy values!
 */
export const booleanEnvironmentSchema = z
  .enum(['0', '1', 'true', 'false', ''])
  .transform((value) => value === 'true' || value === '1')
  .default('false')
  .or(z.boolean().default(false))
  .or(z.number().transform((value) => value === 1))
